import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <hr className="footer-line" />
      <div className="footer-text">
        <p>© Athena Infonomics. All rights reserved 2022<br></br>Website Developed by <a href='https://apnito.in/' target="_blank" rel="noopener noreferrer">Apnito</a></p>
      </div>
    </footer>
  );
};

export default Footer;
