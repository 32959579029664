// CaseStudy.js
import React from 'react';
import "../CaseStudy.css";
import Navbar from '../../../Navbar/Navbar';
import Footer from '../../../Footer/Footer';

const CaseStudy1 = () => {
    return (
      <>
        <Navbar transparentBackground={false} />
        <br />
        <br />
        <br />
        <div className="info-container">
          <div className="header">
            <p>Case Study 5</p>
            <img src={require('./../../../ProjectUnited/Assets/border-3.png')} alt="header-design-1" />
          </div>
          <div className="title">
            <p>Bastar Community Development Project</p>
          </div>
          <div className="card-container">
            {generateCards()}
          </div>
          <br />
          <div className="bottom-section">
            <div className="circular-image">
              <img src={require('../Assets/case_study_card_icon_bottom.png')} alt="Circular Icon" />
              <p><span>Impact: </span>Sustainable improvement in quality of life and well-being of marginalized communities</p>
            </div>
          </div>
        </div>
      <Footer/>
      </>
    );
  };

const generateCards = () => {
    const cardData = [
        { 
          id: 1, 
          points: [
            'Funding',
            'Identification of implementation partner',
            'Identification of problems & needs'
          ], 
          image: require('../Assets/case_study_card_icon_1.png'),
          text: 'Inputs'
        },
        { 
          id: 2, 
          points: [
            "Economic Security",
            "Social Empowerment",
            "Political Empowerment",
            "Networking with Government agencies and leveraging resources"
        ], 
          image: require('../Assets/case_study_card_icon_2.png'),
          text: 'Activities'
        },
        { 
          id: 3, 
          points: [
            'Financial assistance for livelihood promotion accessed by 26 SHGs are linked from National Rural Livelihood Mission (NRLM) to the sum of ₹6.15 lakhs including NRLM, nationalized banks and district cooperative bank',
            'Women were mobilized under 60 SHGs with 720 women and they are federated under 6 CLAs',
            'Small enterprises have been set up by 383 women'
          ], 
          image: require('../Assets/case_study_card_icon_3.png'),
          text: 'Outputs'
        },
        { 
          id: 4, 
          points: [
            'No new child marriages in the impacted villages',
            'No new school dropouts',
            'Entrepreneurial skills built by 700 women',
            'Improved access to welfare schemes and constructing village infrastructure like public toilets'
        ], 
          image: require('../Assets/case_study_card_icon_4.png'),
          text: 'Outcomes'
        },
      ];
      

  return cardData.map((card) => (
    <div key={card.id} className="card">
      <div className="card-top">
        <img className="card-image" src={card.image} alt={`Point ${card.id}`} />
        <p className="card-image-text">{card.text}</p>
      </div>
      <div className="card-content">
        <ul>
          {card.points.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      </div>
    </div>
  ));
};

export default CaseStudy1;
