import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Components/Home/Home';
import Foreword from './Components/Foreword/Foreword';
import ProjectUnited from './Components/ProjectUnited/ProjectUnited';
import StChristophersCollegeOfEducation from './Components/ParticipatingInstitutions/StChristophersCollegeOfEducation';
import CaseStudy11 from './Components/ParticipatingInstitutions/CaseStudies/StChristophersCollegeOfEducation/CaseStudy1';
import LadyDoakCollege from './Components/ParticipatingInstitutions/LadyDoakCollege';
import CaseStudy21 from './Components/ParticipatingInstitutions/CaseStudies/LadyDoakCollege/CaseStudy1';
import ChristDeemedToBeUniversity from './Components/ParticipatingInstitutions/ChristDeemedToBeUniversity';
import CaseStudy31 from './Components/ParticipatingInstitutions/CaseStudies/ChristUniversity/CaseStudy1';
import CaseStudy32 from './Components/ParticipatingInstitutions/CaseStudies/ChristUniversity/CaseStudy2';
import CaseStudy33 from './Components/ParticipatingInstitutions/CaseStudies/ChristUniversity/CaseStudy3';
import CaseStudy34 from './Components/ParticipatingInstitutions/CaseStudies/ChristUniversity/CaseStudy4';
import CaseStudy35 from './Components/ParticipatingInstitutions/CaseStudies/ChristUniversity/CaseStudy5';
import TheAmericanCollege from './Components/ParticipatingInstitutions/TheAmericanCollege';
import CaseStudy41 from './Components/ParticipatingInstitutions/CaseStudies/TheAmericanCollege/CaseStudy1';
import WomensChristianCollege from './Components/ParticipatingInstitutions/WomensChristianCollege';
import CaseStudy51 from './Components/ParticipatingInstitutions/CaseStudies/WomensChristianCollege/CaseStudy1';


function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/foreword' element={<Foreword/>} />
          <Route path='/project-united' element={<ProjectUnited/>}/>
          <Route path='/participating-institutions/st-christophers-college-of-education' element={<StChristophersCollegeOfEducation/>}/>
          <Route path='/participating-institutions/st-christophers-college-of-education/case-study-1' element={<CaseStudy11/>}/>
          <Route path='/participating-institutions/lady-doak-college' element={<LadyDoakCollege/>}/>
          <Route path='/participating-institutions/lady-doak-college/case-study-1' element={<CaseStudy21/>}/>
          <Route path='/participating-institutions/christ-deemed-to-be-university' element={<ChristDeemedToBeUniversity/>}/>
          <Route path='/participating-institutions/christ-deemed-to-be-university/case-study-1' element={<CaseStudy31/>}/>
          <Route path='/participating-institutions/christ-deemed-to-be-university/case-study-2' element={<CaseStudy32/>}/>
          <Route path='/participating-institutions/christ-deemed-to-be-university/case-study-3' element={<CaseStudy33/>}/>
          <Route path='/participating-institutions/christ-deemed-to-be-university/case-study-4' element={<CaseStudy34/>}/>
          <Route path='/participating-institutions/christ-deemed-to-be-university/case-study-5' element={<CaseStudy35/>}/>
          <Route path='/participating-institutions/the-american-college' element={<TheAmericanCollege/>}/>
          <Route path='/participating-institutions/the-american-college/case-study-1' element={<CaseStudy41/>}/>
          <Route path='/participating-institutions/womens-christian-college' element={<WomensChristianCollege/>}/>
          <Route path='/participating-institutions/womens-christian-college/case-study-1' element={<CaseStudy51/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;