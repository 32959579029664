import React from 'react';
import "./Foreword.css";
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

const Forward = () => {
  return (
    <>
    <Navbar transparentBackground={false} />
    <br></br>
    <br></br>
    <br></br>
    <div className="info-container">
      <div className="header-1">
        <p>FOREWORD</p>
        <img src={require('../ProjectUnited/Assets/border-3.png')} alt="header-design-1" />
      </div>
      <div className="circle-images">
        <div className="circle-image">
          <img src={require('./Assets/Pareena.jpeg')} alt="Pareena G. Lawrence - President" />
          <div className='circle-img-text'>
            <p>Pareena G. Lawrence</p>
            <p1>President of the United Board</p1>
          </div>
        </div>
        {/* Other circle images */}
      </div>
      <p className="description">
        The United Board for Christian Higher Education in Asia is an organization that works with
        higher education institutions across Asia. Its mission is to develop the whole person
        intellectually, spiritually, and ethically.
        <br /><br />
        The United Board engages in various programs and activities to achieve its mission. One
        such activity is the Christian Higher Education Impact Study to understand the impact and the
        role played by Christian Higher Education Institutions (HEIs) in improving the overall socio-economic 
        development of the regions and the challenges they face in India.
        <br /><br />
        The study covered more than 100 Christian HEls across India and collected data on students,
        faculty, curriculum, pedagogy, alumni, industry linkages, community engagement,
        technology, and financial assistance. The study concluded that although Christian HEls have
        always worked with their communities and provided leadership in the past, there is a need to
        re-strategize themselves in line with the aspirations of faculty, students, and the community to
        maintain their model role in the future.
        <br /><br />
        To this end, the United Board collaborated with Athena Infonomics for a pilot project in five
        of their network institutions to help them meet some of their challenges. As a result of
        detailed interviews, discussions, and surveys with all the stakeholders of the five institutions,
        recommendations were made to their leadership teams. Feedback from the leadership teams
        of the institutions provided insights on practices with the broadest impact achievable in the
        least amount of time. Some of these best practices are shared on this website.
        <br /><br />
        As you go through these web pages, we hope you will find it helpful for your institutions. As
        we journey together, let us rediscover our vision and mission, let us honor the sacrifices of
        our institutional founders, and above all, let us look up to God, who gives wisdom generously
        to all so that we can create the next generation of leaders who will go on to lead India in the
        spirit of justice, liberty, equality, fraternity, human dignity, unity in diversity and integrity as
        enshrined in the constitution of the country.
      </p>
    </div>
    <Footer/>
    </>
  );
};

export default Forward;
