// CaseStudy.js
import React from 'react';
import "../CaseStudy.css";
import Navbar from '../../../Navbar/Navbar';
import Footer from '../../../Footer/Footer';

const CaseStudy1 = () => {
    return (
      <>
        <Navbar transparentBackground={false} />
        <br />
        <br />
        <br />
        <div className="info-container">
          <div className="header">
            <p>Case Study 1</p>
            <img src={require('./../../../ProjectUnited/Assets/border-3.png')} alt="header-design-1" />
          </div>
          <div className="title">
            <p>Making college campus environmentally sensitive</p>
          </div>
          <div className="card-container">
            {generateCards()}
          </div>
          <br />
          <div className="bottom-section">
            <div className="circular-image">
              <img src={require('../Assets/case_study_card_icon_bottom.png')} alt="Circular Icon" />
              <p><span>Impact: </span>Environmentally conscious campus and students</p>
            </div>
          </div>
        </div>
      <Footer/>
      </>
    );
  };

const generateCards = () => {
    const cardData = [
        { 
          id: 1, 
          points: [
            "Aligning internal stakeholders to new policy",
            "Identification and segregation of solid and liquid wastes at source",
            "Management of E-waste and biomedical waste"
        ], 
          image: require('../Assets/case_study_card_icon_1.png'),
          text: 'Inputs'
        },
        { 
          id: 2, 
          points: [
            "Passage of waste into the recycling system and vermicompost unit",
            "Build carbon soak pits with absorbents",
            "Setting up of incineration machines",
            "Signing MoU with KAU Metals for e-waste collection",
            "Conduct environment, energy, and green audits"
        ], 
          image: require('../Assets/case_study_card_icon_2.png'),
          text: 'Activities'
        },
        { 
          id: 3, 
          points: [
            "2800 kg compost generated",
            "Treated water extracted from liquid waste",
            "Incineration of communal wastes like syringes",
            "Donated items to KAU Metals: 25 CPUs, 15 monitors, 50 mouses, 30 keyboards, and 10 printers"
        ], 
          image: require('../Assets/case_study_card_icon_3.png'),
          text: 'Outputs'
        },
        { 
          id: 4, 
          points: [
            "Self-generated compost for college gardens",
            "Treated water is used for watering plants",
            "Students are taught how to dismantle and re-assemble electronic gadgets"
        ], 
          image: require('../Assets/case_study_card_icon_4.png'),
          text: 'Outcomes'
        },
      ];
      

  return cardData.map((card) => (
    <div key={card.id} className="card">
      <div className="card-top">
        <img className="card-image" src={card.image} alt={`Point ${card.id}`} />
        <p className="card-image-text">{card.text}</p>
      </div>
      <div className="card-content">
        <ul>
          {card.points.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      </div>
    </div>
  ));
};

export default CaseStudy1;
