// CaseStudy.js
import React from 'react';
import "../CaseStudy.css";
import Navbar from '../../../Navbar/Navbar';
import Footer from '../../../Footer/Footer';

const CaseStudy1 = () => {
    return (
      <>
        <Navbar transparentBackground={false} />
        <br />
        <br />
        <br />
        <div className="info-container">
          <div className="header">
            <p>Case Study 1</p>
            <img src={require('./../../../ProjectUnited/Assets/border-3.png')} alt="header-design-1" />
          </div>
          <div className="title">
            <p>Access to government scheme</p>
          </div>
          <div className="card-container">
            {generateCards()}
          </div>
          <br />
          <div className="bottom-section">
            <div className="circular-image">
              <img src={require('../Assets/case_study_card_icon_bottom.png')} alt="Circular Icon" />
              <p><span>Impact: </span>Improved income for family and better care for children</p>
            </div>
          </div>
        </div>
      <Footer/>
      </>
    );
  };

const generateCards = () => {
    const cardData = [
        { 
          id: 1, 
          points: [
            'Funding',
            'Identification of implementation partner',
            'Identification of problems & needs'
          ], 
          image: require('../Assets/case_study_card_icon_1.png'),
          text: 'Inputs'
        },
        { 
          id: 2, 
          points: [
            'Assessment of depth of Water source availability',
            'Approval for bore well',
            'Government-approved bore well contractors engaged',
            'Drilling of bore well',
            'Pipe installation',
            'Flushing and cleaning',
            'Installation of submersible Pump set with valve and other accessories',
            'Electrical works',
            'Erection of bore well platform',
            'Pumping water'
          ], 
          image: require('../Assets/case_study_card_icon_2.png'),
          text: 'Activities'
        },
        { 
          id: 3, 
          points: [
            'Availability of fertile land good for cultivation',
            'Harvest of agriculture produce, selling and garnering an income',
            'Repayment of Loan',
            'Generating working capital for re-investment',
            'More cultivation and harvesting',
            'Plans expand the cultivation by adding more land area'
          ], 
          image: require('../Assets/case_study_card_icon_3.png'),
          text: 'Outputs'
        },
        { 
          id: 4, 
          points: [
            'Increased income for the family',
            'Fulfilment of the family’s basic needs',
            'Children especially the needs of the differently abled child are met.  Confidence and planning to buy more land for expanding the agriculture activities'
            
          ], 
          image: require('../Assets/case_study_card_icon_4.png'),
          text: 'Outcomes'
        },
      ];
      

  return cardData.map((card) => (
    <div key={card.id} className="card">
      <div className="card-top">
        <img className="card-image" src={card.image} alt={`Point ${card.id}`} />
        <p className="card-image-text">{card.text}</p>
      </div>
      <div className="card-content">
        <ul>
          {card.points.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      </div>
    </div>
  ));
};

export default CaseStudy1;
