// CaseStudy.js
import React from 'react';
import "../CaseStudy.css";
import Navbar from '../../../Navbar/Navbar';
import Footer from '../../../Footer/Footer';

const CaseStudy1 = () => {
    return (
      <>
        <Navbar transparentBackground={false} />
        <br />
        <br />
        <br />
        <div className="info-container">
          <div className="header">
            <p>Case Study 1</p>
            <img src={require('./../../../ProjectUnited/Assets/border-3.png')} alt="header-design-1" />
          </div>
          <div className="title">
            <p>Building strong faculty</p>
          </div>
          <div className="card-container">
            {generateCards()}
          </div>
          <br />
          <div className="bottom-section">
            <div className="circular-image">
              <img src={require('../Assets/case_study_card_icon_bottom.png')} alt="Circular Icon" />
              <p><span>Impact: </span>Digitally prepared and resilient faculty</p>
            </div>
          </div>
        </div>
      <Footer/>
      </>
    );
  };

const generateCards = () => {
    const cardData = [
        { 
          id: 1, 
          points: [
            'Paramarsh Scheme, UGC', 
            'Institution\'s support on technology access', 
            'Faculty development programs'
          ], 
          image: require('../Assets/case_study_card_icon_1.png'),
          text: 'Inputs'
        },
        { 
          id: 2, 
          points: [
            'Preparation of action plan', 
            'Identification of weak areas and appropriate resource persons', 
            'Regular meetings with mentee institutions', 
            'Conduct training programs and workshops on ChatGPT, web designing & educational technology'
          ], 
          image: require('../Assets/case_study_card_icon_2.png'),
          text: 'Activities'
        },
        { 
          id: 3, 
          points: [
            'Mentored 5 institutions.', 
            'Setting up of Kathleen Instructional Designing Space', 
            'Conducted 12 FDPs'
          ], 
          image: require('../Assets/case_study_card_icon_3.png'),
          text: 'Outputs'
        },
        { 
          id: 4, 
          points: [
            'Prepared institutions to be ready for NAAC accreditation', 
            'Prepared faculty and students to prepare their own E-Content', 
            'Faculty’s digital preparedness'
          ], 
          image: require('../Assets/case_study_card_icon_4.png'),
          text: 'Outcomes'
        },
      ];
      

  return cardData.map((card) => (
    <div key={card.id} className="card">
      <div className="card-top">
        <img className="card-image" src={card.image} alt={`Point ${card.id}`} />
        <p className="card-image-text">{card.text}</p>
      </div>
      <div className="card-content">
        <ul>
          {card.points.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      </div>
    </div>
  ));
};

export default CaseStudy1;
