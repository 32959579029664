import React, { useState, useEffect, useCallback } from 'react';
import './Home.css';
import stockImage1 from "./Asset/stockImage1.png";
import stockImage3 from "./Asset/stockImage3.png";
import stockImage5 from "./Asset/stockImage5.jpg";
import stockImage7 from "./Asset/stockImage7.JPG";
import Navbar from '../Navbar/Navbar';


const Home = () => {
  const images = [
    stockImage1,
    stockImage3,
    stockImage5,
    stockImage7,
  ];

  const [currentImage, setCurrentImage] = useState(0);

  const nextImage = useCallback(() => {
    setCurrentImage((prevImage) => (prevImage + 1) % images.length);
  }, [images.length]);

  const prevImage = useCallback(() => {
    setCurrentImage((prevImage) => (prevImage - 1 + images.length) % images.length);
  }, [images.length]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      nextImage();
    }, 5000);

    return () => clearInterval(intervalId);
  }, [currentImage, nextImage]);

  return (
    <>
    <div className="home-container">
      <Navbar transparentBackground={false}/>
      <div className="slider-container">
        <button className="arrow-btn left-arrow" onClick={prevImage}>
          {'<'}
        </button>
        <div className="image-container-1">
          <img src={images[currentImage]} alt={`Slide ${currentImage + 1}`} className="slider-image" />
        </div>
        <button className="arrow-btn right-arrow" onClick={nextImage}>
          {'>'}
        </button>
      </div>
      <div className="slogan-container">
        <div className="slogan-wrapper">
          <p className='company-slogan-1'>Project UNITED</p>
          <p className="company-slogan">Working Together To Impact Education</p>
        </div>
      </div>
    </div>
    </>
  );
};

export default Home;
