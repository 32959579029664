import React, { useState, useEffect, useRef } from 'react';
import './Navbar.css';
import logo from './Assets/UB_logo.png'
import logoImage1 from '../ProjectUnited/Assets/06.png';
import logoImage2 from '../ProjectUnited/Assets/07.png';
import logoImage3 from '../ProjectUnited/Assets/08.png';
import logoImage4 from '../ProjectUnited/Assets/09.png';
import logoImage5 from '../ProjectUnited/Assets/10.png';

const Navbar = ({ transparentBackground }) => {
  const dropdownRef = useRef(null);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isParticipatingDropdownOpen, setParticipatingDropdownOpen] = useState(false);
  const [currentLogo, setCurrentLogo] = useState('');
  const [currentLogoUrl, setCurrentLogoUrl] = useState('');

  const logoMapping = {
    '/': {
      source: logo,
      url: 'https://unitedboard.org/',
    },
    '/participating-institutions/st-christophers-college-of-education': {
      source: logoImage1 , // Replace with the actual logo URL
      url: 'http://scced.edu.in/',
    },

    '/participating-institutions/lady-doak-college': {
      source: logoImage2, // Replace with the actual logo URL
      url: 'https://www.ladydoakcollege.edu.in/',
    },
    '/participating-institutions/christ-deemed-to-be-university': {
      source: logoImage3, // Replace with the actual logo URL
      url: 'https://christuniversity.in/',
    },
    '/participating-institutions/the-american-college': {
      source: logoImage4, // Replace with the actual logo URL
      url: 'https://americancollege.edu.in/',
    },
    '/participating-institutions/womens-christian-college': {
      source: logoImage5, // Replace with the actual logo URL
      url: 'https://wcc.edu.in/',
    },
  };

  useEffect(() => {
    const currentPath = window.location.pathname;
    setCurrentLogoUrl(logoMapping[currentPath]?.url || '');
    setCurrentLogo(logoMapping[currentPath]?.source || '');
  }, []);

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleParticipatingToggle = () => {
    setParticipatingDropdownOpen((prevOpen) => !prevOpen);
  };
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdown = dropdownRef.current;
      const mobileMenu = document.querySelector('.navbar-links');
      const mobileMenuIcon = document.querySelector('.mobile-menu-icon');
      const dropdownItems = document.querySelectorAll('.dropdown-item');
  
      if (
        dropdown &&
        !dropdown.contains(event.target) &&
        !mobileMenu.contains(event.target) &&
        !mobileMenuIcon.contains(event.target) &&
        !Array.from(dropdownItems).some((item) => item.contains(event.target))
      ) {
        setParticipatingDropdownOpen(false);
      }
    };
  
    document.addEventListener('click', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
  
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []); 
  
  const shouldShowLogo = Object.keys(logoMapping).includes(window.location.pathname);

  const getLogoClassName = (url) => {
    const matches = url.match(/\/\/(?:www\.)?([^\/.]+)/);
    return matches ? matches[1] : null;
  };
  
  const logoClassName = getLogoClassName(currentLogoUrl);  


  return (
    <nav className={`navbar ${transparentBackground ? 'transparent-background' : ''}`}>
      <div className="navbar-left">
        {shouldShowLogo && (
          <a href={currentLogoUrl}>
            <img className={logoClassName} src={currentLogo} alt="placeholder" />
          </a>
        )}
      </div>
      <div className={`navbar-links ${isMobileMenuOpen ? 'show-mobile-menu' : ''}`}>
        <a href="/">Home</a>
        <a href="/foreword">Foreword</a>
        <a href="/project-united">Project UNITED</a>
        <div ref={dropdownRef} className={`dropdown ${isParticipatingDropdownOpen ? 'dropdown-open' : ''}`} onClick={handleParticipatingToggle}>
          Participating Institution
          <div className={`dropdown-content ${isParticipatingDropdownOpen ? 'slide-down' : ''}`}>
            <a href="/participating-institutions/st-christophers-college-of-education" className="dropdown-item">St. Christopher's College Of Education</a>
            <a href="/participating-institutions/lady-doak-college" className="dropdown-item">Lady Doak College</a>
            <a href="/participating-institutions/christ-deemed-to-be-university" className="dropdown-item">Christ (Deemed to be University)</a>
            <a href="/participating-institutions/the-american-college" className="dropdown-item">The American College</a>
            <a href="/participating-institutions/womens-christian-college" className="dropdown-item">Women's Christian College</a>
          </div>
        </div>
        <a href="https://unitedboard.org/">United Board</a>
      </div>
      <div className="mobile-menu-icon" onClick={handleMobileMenuToggle}>
        {isMobileMenuOpen ? (
          <div className="close-icon" style={{ color: '#F58027', fontSize: '30px' }}>&#10006;</div>
        ) : (
          <>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;