// CaseStudy.js
import React from 'react';
import "../CaseStudy.css";
import Navbar from '../../../Navbar/Navbar';
import Footer from '../../../Footer/Footer';

const CaseStudy1 = () => {
    return (
      <>
        <Navbar transparentBackground={false} />
        <br />
        <br />
        <br />
        <div className="info-container">
          <div className="header">
            <p>Case Study 2</p>
            <img src={require('./../../../ProjectUnited/Assets/border-3.png')} alt="header-design-1" />
          </div>
          <div className="title">
            <p>Improving livelihood for better life</p>
          </div>
          <div className="card-container">
            {generateCards()}
          </div>
          <br />
          <div className="bottom-section">
            <div className="circular-image">
              <img src={require('../Assets/case_study_card_icon_bottom.png')} alt="Circular Icon" />
              <p><span>Impact: </span>Enhanced economic status of the family and assured education for children</p>
            </div>
          </div>
        </div>
      <Footer/>
      </>
    );
  };

const generateCards = () => {
    const cardData = [
        { 
          id: 1, 
          points: [
            'Funding',
            'Identification of implementation partner',
            'Identification of problems & needs'
          ], 
          image: require('../Assets/case_study_card_icon_1.png'),
          text: 'Inputs'
        },
        { 
          id: 2, 
          points: [
            'Training',
            'Tank preparation',
            'Water filtration and conditioning',
            'Aeration',
            'Temperature control',
            'Water & fish transfer',
            'Marketing'
          ], 
          image: require('../Assets/case_study_card_icon_2.png'),
          text: 'Activities'
        },
        { 
          id: 3, 
          points: [
            'Creation of Assets (fishpond)',
            'Harvest of Fish once in six months',
            'Repayment of Loan',
            'Generating working capital for re-investment'
          ], 
          image: require('../Assets/case_study_card_icon_3.png'),
          text: 'Outputs'
        },
        { 
          id: 4, 
          points: [
            'Increased income for the family',
            'Continuation of Children’s education and space for their higher education',
            'Fulfilment of the family’s basic needs',
            'Planning to fulfil the long-lasting wish of constructing a house for her family'
            
          ], 
          image: require('../Assets/case_study_card_icon_4.png'),
          text: 'Outcomes'
        },
      ];
      

  return cardData.map((card) => (
    <div key={card.id} className="card">
      <div className="card-top">
        <img className="card-image" src={card.image} alt={`Point ${card.id}`} />
        <p className="card-image-text">{card.text}</p>
      </div>
      <div className="card-content">
        <ul>
          {card.points.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      </div>
    </div>
  ));
};

export default CaseStudy1;
