import React, { useState } from 'react';
import "./ProjectUnited.css"
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

const ProjectUnited = () => {
  const [selectedImage, setSelectedImage] = useState(1);

  const handleImageClick = (imageIndex) => {
    setSelectedImage(imageIndex);
  };

  return (
    <>
    <Navbar transparentBackground={false} />
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <div className='container'>
        <div className='header-2'>
            <h1>Project UNITED</h1>
            <img src={require('./Assets/border-3.png')} alt="header-design" />
        </div>
        
        <div className='sub-header-1'>
            <h2>Approach</h2>
            <p>
                The<span> United Board</span> endeavors to respond to the diverse challenges and opportunities facing our colleagues in higher education in Asia, especially where the needs are great. We share their interests in developing institutions that offer multidisciplinary education and that nurture a spirit of compassion, equity, reconciliation, social responsibility, and mutual respect among religious and cultural traditions.
            </p>
        </div>

        <div className="image-container">
            <div
                className={`strategy-logo ${selectedImage === 1 ? 'selected' : ''}`}
                onClick={() => handleImageClick(1)}
            >
                <img src={require('./Assets/Strategy1.png')} alt='' />
                <p>Strategy</p>
            </div>
            <div
                className={`impact-logo ${selectedImage === 2 ? 'selected' : ''}`}
                onClick={() => handleImageClick(2)}
            >
                <img src={require('./Assets/Impact-Statement.png')} alt='' />
                <p>Impact Assessment</p>
            </div>
            <div
                className={`united-logo ${selectedImage === 3 ? 'selected' : ''}`}
                onClick={() => handleImageClick(3)}
            >
                <img src={require('./Assets/framework.png')} alt='' />
                <p>Conceptual Framework</p>
            </div>
        </div>
        <div className="description-box">
            {selectedImage === 1 && (
                <p>
                Our strategy is ecumenical and inclusive: our primary involvement is with Christian institutions, but we also reach out to other colleges and universities that share our commitment to whole person education. Solidarity with educators at these institutions forms the foundation of our work. Through their insights we identify needs and through their creative responses we find ways to address them collaboratively.
                <br /><br />
                <span>Our programs and grants</span> support institutions to improve the quality of education. We sponsor professional training for faculty, administrators, and trustees, and foster linkages and networks among Asian institutions of higher education. Our current focus is on five program areas: leadership development for higher education; faculty development for enhanced teaching, learning, and research; campus-community partnerships; culture and religion in Asia; and special initiatives.
                </p>
            )}
            {selectedImage === 2 && (
                <p>
                <span>Education</span> is a critical and essential tool for social empowerment, equitable growth, and development. Higher education is even more crucial in a country like India with nearly   10.5% of the population in the age category of 10-14 years and 19.1% of its population as youth (15-24 years). Christian HEIs have played an instrumental role since independence in creating an educated society. In India, establishment of Christian Higher Education Institutions (HEIs) can be traced back to the 19th century and these institutions have been an important lever of social change. The aim of these HEIs was to impart values and character to their learners’ so that they could be better people and leaders in their respective communities. The colleges also targeted the poor and disadvantaged in society to give them a leg up on the socio-economic ladder. To a large extent, these continue to be the main objective of Christian HEIs even to this day, as evidenced by their vision and mission statements.
                <br /><br />
                <span>The United Board</span> and <span>All-India Association for Christian Higher Education (AIACHE)</span> aim at the renewal and growth of member colleges as well as the promotion of the quality, value-orientation, and social relevance of Indian higher education to achieve the goal of national integration and secularism. India has over 400 Christian higher educational institutions including State Universities, deemed to be Universities, Medical Colleges, Engineering Colleges, Arts-Science-Commerce Colleges, Colleges of Education, Training institutions, Nursing Colleges, etc.  
                <br /><br />
                Recently, the <span>United Board for Christian Higher Education</span> in Asia conducted an impact assessment study for the Christian HEIs in India with an aim to understand the role played by these institutions in improving the overall socio-economic development of region and beneficiaries, to document the impact created by some of these institutions and to identify ways to replicate and maximise the impact areas. 
                <br /><br />
                The study reported the status of Christian HEIs in terms of their performance across various dimensions such as students, faculty, alumni, curriculum, technology, financial assistance, industrial linkage, and community engagement of Christian HEIs in terms of their performance across various dimensions. For this study, it is envisioned that ‘creating an impact’ was defined as the possession of characteristics of being driven by purpose (vision & mission of HEI), being consistent in performance, taking the lead (new initiatives, courses, pedagogy, etc.), being adaptive (revision of curriculum, alternative sources of funding, etc.), overcoming tough challenges, ability to anticipate and be prepared and capacity to work with limited resources/constraints. While the Christian HEIs have been providing leadership and community-linked initiatives, it is imperative to re-strategize and connect the past to the future such that they become models for future education and development in line with aspirations of community, students, and teaching faculty.  In this context, an attempt has been made by United Board to rollout comprehensive strategy to reenergize select HEIs to prepare for the future and expect to create blueprint for other institutes to follow through <span>Project UNITED</span>. 
                </p>
            )}
            {selectedImage === 3 && (
                <p>
                The HEIs create an impact through visionary leadership realized through core areas and enablers. Core areas are those dimensions that primarily drive and influence the performance of the institutions while enablers are those dimensions which level up the performance to the next level. Not all aspects contribute equally to creating an impact. From the perspective of HEIs, some dimensions may be internal and under the control of institutions to influence while some others may be external, and the institutions may have limited influence on them. The dimensions have been classified into a matrix of dependencies based on the extent of institutional control and whether they are external or internal with regard to the institutions.  
                <br /><br />
                <span>The conceptual framework for Project UNITED involves</span>
                <br />
                    1. Structural Evaluation: the current status of HEIs
                    <br />
                    2. Theory of Change: How the Higher Education Institutions projects inputs translate to long term impact through causal output-outcome path
                    <br />
                    3. Model of Change: How the Higher Education Institutions translate their goals into scalable programs for broader usage
                    <br />
                    4. Cognitive/Behavioral Change: How the stakeholders’ needs are addressed to ensure sustainable benefits envisaged under the Project UNITED
                    <br />
                    5. Institutional learning and build for scale and continuous impact: How the Higher Education Institutions focus on institutional learning to scale and continuous impact.
                </p>
            )}
        </div>

        <div className='sub-header-2'>
            <h2>Transformational Outcome</h2>
            <div className='transformation-img'>
                <img src={require('./Assets/01.png')} alt="Transformation 1" />
                <span></span>
                <img src={require('./Assets/02.png')} alt="Transformation 2" />
                <span></span>
                <img src={require('./Assets/05.png')} alt="Transformation 5" />   
                <span></span> 
                <img src={require('./Assets/04.png')} alt="Transformation 4" />
                <span></span>
                <img src={require('./Assets/03.png')} alt="Transformation 3" />
            </div>
        </div>

        <div className='sub-header-3'>
            <h2>Institutional Coverage</h2>
            <div className='institutional-img'>
                <a href="/participating-institutions/st-christophers-college-of-education" className='st-cristopher'>
                    <img src={require('./Assets/06.png')} alt='' />
                </a>
                <span></span>
                <a href='/participating-institutions/lady-doak-college'>
                    <img src={require('./Assets/07.png')} alt='' />
                </a>
                <span></span>
                <a href='/participating-institutions/christ-deemed-to-be-university'>
                    <img src={require('./Assets/08.png')} alt='' />
                </a>
                <span></span>
                <a href='/participating-institutions/the-american-college' className='the-american-college'>
                    <img src={require('./Assets/09.png')} alt='' />
                </a>
                <span></span>
                <a href='/participating-institutions/womens-christian-college'>
                    <img src={require('./Assets/10.png')} alt='' />
                </a>
            </div>
        </div>
    </div>
    <Footer/>
    </>
  );
};

export default ProjectUnited;
